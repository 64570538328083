import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { useColorMode } from '@bestyled/contrib-use-color-mode'
import { copyToClipboard } from '@slipway/theme-components'

import mediaqueries from '../styles/media'
import Icons from './TComponents/icons'


export const SharePageButton: React.FC<{
  delay: number
  active: boolean
  isHomepage?: boolean
}> = ({ active, delay, isHomepage }) => {
  const [hasCopied, setHasCopied] = useState<boolean>(false)
  const [colorMode] = useColorMode()
  // TODO: Fix this when doing dark / light mode
  const isDark = colorMode === `dark` || true
  const fill = isDark && !isHomepage ? '#fff' : '#000'

  function copyToClipboardOnClick() {
    if (hasCopied) {
      return
    }

    copyToClipboard(window.location.href)
    setHasCopied(true)

    setTimeout(() => {
      setHasCopied(false)
    }, 1000)
  }

  return (
    <IconWrapper
      data-active={active}
      delay={delay}
      isDark={isDark}
      onClick={copyToClipboardOnClick}
      data-a11y='false'
      aria-label='Copy URL to clipboard'
      title='Copy URL to clipboard'
    >
      <Icons.Link fill={fill} />
      <ToolTip isDark={isDark} hasCopied={hasCopied}>
        Copied
      </ToolTip>
    </IconWrapper>
  )
}

const IconWrapper = styled.button<{
  delay: number
  ['data-active']: boolean
  isDark: boolean
}>`
  opacity: 0.5;
  position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  background: inherit;

  &:hover {
    opacity: 1;
  }

  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.9)
      ${(p) => p.delay}ms,
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.9) ${(p) => p.delay}ms;

  pointer-events: ${(p) => (p['data-active'] ? 'initial' : 'none')};
  opacity: ${(p) => (p['data-active'] ? 0.5 : 0)};
  transform: ${(p) =>
    p['data-active'] ? 'translateX(0)' : 'translateX(12px)'};

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: 0;
    top: -30%;
    width: 100%;
    height: 160%;
    border: 2px solid ${(p) => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.tablet`
        display: inline-flex;
        transform: scale(0.708);
        margin-left: 10px;
    
    
        &:hover {
          opacity: 0.5;
        }
      `}
`

// This is based off a codepen! Much appreciated to: https://codepen.io/aaroniker/pen/KGpXZo
const MoonOrSun = styled.div<{ isDark: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${(p) => (p.isDark ? '4px' : '2px')} solid
    ${(p) => p.theme.colors.primary};
  background: ${(p) => p.theme.colors.primary};
  transform: scale(${(p) => (p.isDark ? 0.55 : 1)});
  transition: all 0.45s ease;
  overflow: ${(p) => (p.isDark ? 'visible' : 'hidden')};

  &::before {
    content: '';
    position: absolute;
    right: -9px;
    top: -9px;
    height: 24px;
    width: 24px;
    border: 2px solid ${(p) => p.theme.colors.primary};
    border-radius: 50%;
    transform: translate(${(p) => (p.isDark ? '14px, -14px' : '0, 0')});
    opacity: ${(p) => (p.isDark ? 0 : 1)};
    transition: transform 0.45s ease;
  }

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: -4px 0 0 -4px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 -23px 0 ${(p) => p.theme.colors.primary},
      0 23px 0 ${(p) => p.theme.colors.primary},
      23px 0 0 ${(p) => p.theme.colors.primary},
      -23px 0 0 ${(p) => p.theme.colors.primary},
      15px 15px 0 ${(p) => p.theme.colors.primary},
      -15px 15px 0 ${(p) => p.theme.colors.primary},
      15px -15px 0 ${(p) => p.theme.colors.primary},
      -15px -15px 0 ${(p) => p.theme.colors.primary};
    transform: scale(${(p) => (p.isDark ? 1 : 0)});
    transition: all 0.35s ease;

    ${(p) => mediaqueries.tablet`
          transform: scale(${p.isDark ? 0.92 : 0});
        `}
  }
`

const MoonMask = styled.div<{ isDark: boolean }>`
  position: absolute;
  right: -1px;
  top: -8px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 0;
  background: inherit;
  transform: translate(${(p) => (p.isDark ? '14px, -14px' : '0, 0')});
  opacity: ${(p) => (p.isDark ? 0 : 1)};
`

export const DarkModeToggle: React.FC<{ delay: number; active: boolean }> = ({
  active,
  delay
}) => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`

  function toggleColorMode(event) {
    event.preventDefault()
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <IconWrapper
      data-active={active}
      delay={delay}
      isDark={isDark}
      onClick={toggleColorMode}
      data-a11y="false"
      aria-label={isDark ? 'Activate light mode' : 'Activate dark mode'}
      title={isDark ? 'Activate light mode' : 'Activate dark mode'}
    >
      <MoonOrSun isDark={isDark} />
      <MoonMask isDark={isDark} />
    </IconWrapper>
  )
}

const ToolTip = styled.div<{ isDark: boolean; hasCopied: boolean }>`
  position: absolute;
  padding: 4px 13px;
  background: ${(p) => (p.isDark ? '#000' : 'rgba(0,0,0,0.1)')};
  color: ${(p) => (p.isDark ? '#fff' : p.theme.colors.buttonHoverBg)};
  border-radius: 5px;
  font-size: 14px;
  top: -35px;
  opacity: ${(p) => (p.hasCopied ? 1 : 0)};
  transform: ${(p) => (p.hasCopied ? 'translateY(-3px)' : 'none')};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${(p) => (p.isDark ? '#000' : 'rgba(0,0,0,0.1)')};
  }
`
