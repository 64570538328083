import styled from 'styled-components'

import mediaqueries from '../styles/media'

const Section = styled.section<{ narrow?: boolean; relative?: boolean; backgroundImage?: string }>`
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;
  /* padding: 0 4rem; */
  ${(p) =>
    p.backgroundImage ??
    `
  background-image: url(${p.backgroundImage});
  `}
  ${mediaqueries.desktop`
    max-width: 850px;
  `};

  ${(p) =>
    p.narrow
      ? mediaqueries.tablet`
          padding: 0 2rem;
          max-width: 527px;
        `
      : mediaqueries.tablet`
          padding: 0 2vw;
          max-width: 567px;
          width: 90vw;
        `}

  ${mediaqueries.phablet`
    max-width: 100%;
  `};
`

export default Section
