import * as React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { useI18n } from '@bestyled/primitives'
import Section from './Section'
import mediaqueries from '../styles/media'
import SocialLinks from './SocialLinks'

const Footer: React.FC<{}> = () => {
  const { isCompatible } = useI18n()

  const results = useStaticQuery(graphql`
    {
      allSite {
        edges {
          node {
            siteMetadata {
              externalFooterLinks {
                name
                url
                locale
              }
              name
              copyrightOwner
              copyrightLink
              social {
                url
                name
              }
            }
          }
        }
      }
      allPage(filter: { menu: { glob: "footer*" } }, sort: { fields: menu, order: ASC }) {
        edges {
          node {
            id
            menu
            slug
            lang
            title
          }
        }
      }
    }
  `)

  const { copyrightOwner, copyrightLink, social, externalFooterLinks } = results.allSite.edges[0].node.siteMetadata

  const footerLinks = externalFooterLinks.filter((link) => isCompatible(link.locale))

  const navItems = results.allPage.edges
    .map((edge) => ({
      id: edge.node.id,
      title: edge.node.menuTitle || edge.node.title,
      href: edge.node.slug,
      lang: edge.node.lang
    }))
    .filter((node) => isCompatible(node.lang))

  return (
    <>
      <FooterGradient />
      <Section narrow className='container'>
        <HoritzontalRule />
        <FooterContainer>
          <FooterText>
            <FooterLinkContainer>
              <HomeLinkContainer>
                © {new Date().getFullYear()}&nbsp;
                <FooterAnchor href={copyrightLink} target="_blank">{copyrightOwner}</FooterAnchor>
              </HomeLinkContainer>
              {footerLinks.map(({ name, url }, key) => (
                // @ts-ignore
                <FooterAnchorLink key={key} href={url} target="_blank">
                  {name}
                </FooterAnchorLink>
              ))}
              {navItems.map((item) => (
                <FooterLink key={item.id} to={item.href}>
                  {item.title}
                </FooterLink>
              ))}
            </FooterLinkContainer>
          </FooterText>
          <FooterSocial>
            <SocialLinks links={social} />
          </FooterSocial>
        </FooterContainer>
      </Section>
    </>
  )
}

export default Footer

const HoritzontalRule = styled.div`
  margin: 2vh auto 4vh;
  border-bottom: 1px solid ${(p) => p.theme.colors.horizontalRule};
  position: relative;

  ${mediaqueries.desktop`
    border-top: 1px solid ${(p) => p.theme.colors.horizontalRule};
    height: 10px;
    border-bottom: none;
    margin: 1% 0;

  `}

  ${mediaqueries.tablet`
    top: -30px;
    height: 10px;
    border-bottom: none;
    border-top: 1px solid ${(p) => p.theme.colors.horizontalRule};
    position: relative;
    margin: 1% 0;
  `}

  ${mediaqueries.phablet`
    display: none;
  `}
`

const FooterContainer = styled.div`
  position: relative;
  color: ${(p) => p.theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  font-weight: ${({ theme }) => theme.fonts.weights.normal};
  display: flex;
  align-items: baseline;
  padding-bottom: 80px;
  flex-wrap: wrap;
  flex-grow: revert;

  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;

  ${mediaqueries.desktop_large`
    height: 150px;
    display: flex;
    align-items: baseline;
    padding-bottom: 80px;
    flex-wrap: wrap;
    flex-grow: revert;

    flex-direction: row;
    justify-content: space-evenly;
    white-space: nowrap;
  `}

  ${mediaqueries.desktop_medium`
    height:180px;
    flex-flow: column-reverse nowrap;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 20px;


  `}

  ${mediaqueries.desktop`
    height: 150px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-grow: revert;
    flex-flow: column-reverse nowrap;
    white-space: normal;
  `}
  ${mediaqueries.tablet`
    height: calc(100% + 200px);
    padding-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: revert;
    flex-flow: column-reverse nowrap;
    white-space: normal;
    margin: -5%;
  `}

  ${mediaqueries.phablet`
    height: calc(100% + 100px);
    padding-bottom: 20px;
    justify-content: space-between;
    flex-flow: column-reverse nowrap;
    flex-wrap: wrap;
    flex-grow: revert;
    margin: -5%;
  `}
`

const FooterText = styled.div`
  font-family: ${(p) => p.theme.fonts.serif};
  font-weight: ${(p) => p.theme.fontWeights.normal};
  font-size: ${(p) => p.theme.fontSizes[1]}px;
  display: flex;
  line-height: 30px;

  ${mediaqueries.desktop`
    margin-bottom: 20px;
    line-height: 20px;
    width: 70%;
    height: calc(100% + 50px);
  `}
  ${mediaqueries.tablet`
    padding: 10px 0;
    line-height: 20px;
    width: 70%;
    height: calc(100% + 50px);
    flex-direction: column;
    align-items: flex-end;
  `}

  ${mediaqueries.phablet`
    margin: 10px auto 10px;
    height: calc(100% + 50px);
    margin: 10px auto;
    flex-direction: column;
    align-items: flex-end;
  `}
`

const FooterLinkContainer = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  flex-flow: row wrap;

  ${mediaqueries.desktop`
    justify-content: center;
  `}

  ${mediaqueries.tablet`
    flex-direction: column;
    margin-top: 0;
    align-content: flex-end;
  `}
`

const FooterSocial = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  ${mediaqueries.desktop`
    display: flex;
  `}
  ${mediaqueries.phablet`
    padding: 1vh 0;

  `}
`
const HomeLinkContainer = styled.div`
  white-space: nowrap;
`

const FooterAnchor = styled.a`
  margin-right: 7px;
  color: ${(p) => p.theme.colors.brand};

  font-family: ${(p) => p.theme.fonts.paragraph};
  font-weight: ${(p) => p.theme.fontWeights.normal};
  font-size: ${(p) => p.theme.fontSizes[2]}px;
  ${mediaqueries.desktop_up`
    ::after {
      content: ' | ';
      color: ${(p) => p.theme.colors.grey};
    }
  `}
`


const FooterLink = styled(Link)`
  font-family: ${(p) => p.theme.fonts.paragraph};
  font-weight: ${(p) => p.theme.fontWeights.normal};
  font-size: ${(p) => p.theme.fontSizes[2]}px;
  margin-right: 7px;
  color: ${(p) => p.theme.colors.grey};
  display: block;
  text-align: right;

  :hover {
    color: ${(p) => p.theme.colors.brand};
  }
  :last-of-type::after {
    content: '' !important;
    color: ${(p) => p.theme.colors.grey};
  }
  ${mediaqueries.desktop_up`

  ::after {
    content: ' | ';
    color: ${(p) => p.theme.colors.grey};
  }
  :first-of-type ::before {
    content: ' | ';
    color: ${(p) => p.theme.colors.grey};
  }
  ::first-line ::before {
    content: ' | ';
    color: ${(p) => p.theme.colors.grey};
  }
  `}
`
const FooterAnchorLink = styled.a`
  font-family: ${(p) => p.theme.fonts.paragraph};
  font-weight: ${(p) => p.theme.fontWeights.normal};
  font-size: ${(p) => p.theme.fontSizes[2]}px;
  margin-right: 7px;
  color: ${(p) => p.theme.colors.grey};
  display: block;
  text-align: right;

  :hover {
    color: ${(p) => p.theme.colors.brand};
  }
  :last-of-type::after {
    content: '' !important;
    color: ${(p) => p.theme.colors.grey};
  }
  ${mediaqueries.desktop_up`

  ::after {
    content: ' | ';
    color: ${(p) => p.theme.colors.grey};
  }
  :first-of-type ::before {
    content: ' | ';
    color: ${(p) => p.theme.colors.grey};
  }
  ::first-line ::before {
    content: ' | ';
    color: ${(p) => p.theme.colors.grey};
  }
  `}
`

const FooterGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${(p) => p.theme.colors.gradient};
  transition: ${(p) => p.theme.colorModeTransition};
`
