/* eslint-disable no-restricted-globals */
import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { commonProps, CommonProps as _CommonProps } from '@bestyled/system'

import styled from 'styled-components'

import { useI18n } from '@bestyled/primitives'
import mediaqueries from '../styles/media'

import { SharePageButton } from './Navigation.IconButtons'
import LanguageLinks from './Navigation.LanguageLinks'

// type commonProps = _commonProps & any
type CommonProps = _CommonProps & any
const siteQuery = graphql`
  {
    allPage(filter: { menu: { glob: "header*" } }, sort: { fields: menu, order: ASC }) {
      edges {
        node {
          id
          menu
          menuTitle
          lang
          slug
          title
        }
      }
    }
  }
`

export const NavigationMenuDesktop: React.FC<{
  active: boolean
  isHomepage?: boolean
  navigateOut: (event: any, path: any) => void
}> = ({ active, navigateOut, isHomepage }) => {
  const { allPage: menuItems } = useStaticQuery(siteQuery)
  const { isCompatible, locale } = useI18n()

  const navItems = menuItems.edges
    .map((edge) => ({
      id: edge.node.id,
      title: edge.node.menuTitle || edge.node.title,
      href: edge.node.slug,
      lang: edge.node.lang
    }))
    .filter((node) => isCompatible(node.lang))

  return (
    <Right>
      <Nav>
        <DesktopNavList id='menu-list'>
          <NavItems items={navItems} active={active} handleClick={navigateOut} isHomepage={isHomepage} />
        </DesktopNavList>
      </Nav>
    </Right>
  )
}

export default NavigationMenuDesktop

const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  background: inherit;
`

const Nav = styled.nav`
  background: inherit;
`

const DesktopNavList = styled.ul`
  list-style: none;
  background: inherit;

  ${mediaqueries.tablet`
    display: none;
  `};
`

const NavItems: React.FC<{
  items: { id: string; title: string; href: string; disabled?: boolean }[]
  active: boolean
  isHomepage?: boolean

  handleClick: Function
}> = ({ active, items, handleClick, isHomepage }) => {
  const totalItems = items.length + 2

  const { locale, languages, getLangPathForLocale } = useI18n()

  const symbolButtons = [
    <NavItem mr={10} key=':sharepage' isHomepage={!!isHomepage}>
      <SharePageButton active={active} delay={active ? 30 * 2 : 30 * (totalItems - 1)} isHomepage={!!isHomepage} />
    </NavItem>
  ]

  return (
    <>
      {[
        ...items.map((nav, index) => {
          const delay = active ? 30 * (totalItems - index - 4) : 30 * index

          return (
            <NavItem key={nav.id} isHomepage={!!isHomepage}>
              <NavAnchor
                data-active={active || undefined}
                disabled={nav.disabled}
                to={nav.href}
                delay={delay}
                as={Link}
                tabIndex={active ? 0 : -1}
                onClick={(event) => handleClick(event, nav.href)}
                data-a11y='false'
                isHomepage={!!isHomepage}
                getProps={({ isPartiallyCurrent }) => (isPartiallyCurrent ? { 'data-iscurrent': 'true' } : null)}
              >
                {nav.title}
              </NavAnchor>
            </NavItem>
          )
        }),
        ...symbolButtons
      ]}
      <LanguageLinks active={active} handleClick={handleClick} isHomepage={!!isHomepage} />
    </>
  )
}

const NavItem = styled.li<CommonProps>`
  display: inline-block;
  margin-right: 10px;
  background: inherit;
  * {
    fill: ${(p) => (p.isHomepage ? p.theme.colors.card : p.theme.colors.headings)};
  }

  &:last-child {
    margin-right: 5px;
  }

  ${mediaqueries.tablet`
    margin-right: 30px;

    &:first-child {
      display: none;
    }

  `};

  ${mediaqueries.phablet`
    display: block;
    margin: 0 auto;

    &:first-child {
      display: block;
    }

  `};

  ${commonProps}
`

const NavAnchor = styled.a<any>`
  font-family: ${(p) => p.theme.fonts.menu};
  padding-left: 8px;
  text-transform: ${(p) => p.theme.textTransforms.topmenu};
  letter-spacing: 2px;
  display: flex;
  font-weight: ${(p) => p.theme.fontWeights.menu};
  font-size: ${(p) => p.theme.fontSizes[1]}px;
  height: 40px;
  align-items: center;
  color: ${(p) => (p.isHomepage ? p.theme.colors.card : p.theme.colors.headings)};
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.9) ${(p) => p.delay}ms,
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.9) ${(p) => p.delay}ms;

  pointer-events: ${(p) => (p['data-active'] ? 'initial' : 'none')};
  opacity: ${(p) => (p['data-active'] ? (p.disabled ? 0.15 : 0.6) : 0)};
  transform: ${(p) => (p['data-active'] ? 'translateX(0)' : 'translateX(12px)')};

  &[data-iscurrent='true'] {
    &::after {
      content: '';
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 4px;
      height: 1px;
      width: 20px;
      background: ${(p) => p.theme.colors.grey};
    }
  }

  &:hover {
    opacity: ${(p) => (p.disabled ? 0.15 : 1)};
  }

  &:focus {
    outline: none;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -25%;
    top: 2%;
    width: 150%;
    height: 100%;
    border: 2px solid ${(p) => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.phablet`
    display: none;
  `};
`

const NavLanguage = styled.a<any>`
  font-family: ${(p) => p.theme.fonts.menu};
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  font-weight: ${(p) => p.theme.fontWeights.menu};
  font-size: ${(p) => p.theme.fontSizes[0]}px;
  align-items: center;
  color: ${(p) => p.theme.colors.card};
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.9) ${(p) => p.delay}ms,
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.9) ${(p) => p.delay}ms;

  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 5px;
  margin-left: 5px;
  bottom: 4px;
  position: relative;

  pointer-events: ${(p) => (p['data-active'] ? 'initial' : 'none')};
  opacity: ${(p) => (p['data-active'] ? (p.disabled ? 0.15 : 0.6) : 0)};
  transform: ${(p) => (p['data-active'] ? 'translateX(0)' : 'translateX(12px)')};

  &[data-iscurrent='true'] {
    border: 1px solid ${(p) => p.theme.colors.grey};
  }

  &:hover {
    opacity: ${(p) => (p.disabled ? 0.15 : 1)};
  }

  &:focus {
    outline: none;
  }

  ${mediaqueries.phablet`
    display: none;
  `};
`
