import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle<any>`
  :root {
    --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
    --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  }

  @font-face {
    font-family: ${(p) => p.theme.fonts.sans};
    font-weight: 400;
    font-style: normal;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-display: block;
  }

  :root {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    cursor: default;
    font-size: 0.625rem;
    line-height: 1.4;
  }

  body {
    font-family: ${(p) => p.theme.fonts.sans};
    font-size: 1.6rem;
    margin: 0;
    font-weight: 400;
    height: 100%;
  }

  article {
    word-break: break-word;
  }

  button,
  a {
    text-decoration: none;
    cursor: pointer;
  }

  a:focus {
    outline: none;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
    align-self: center;
  }

  input,
  textarea,
  select,
  button {
    font-family: ${(p) => p.theme.fonts.button};
  }

  .underline {
    text-decoration: underline;
  }

  button,
  input,
  select,
  textarea {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace;
  }

  fieldset,
  button {
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  audio:not([controls]) {
    display: none;
  }

  details {
    display: block;
  }

  input {
    &:focus,
    &:active {
      outline: none;
    }

    &[type="number"] {
      width: auto;
    }
  }

  img.Image__Zoom ~ div {
    background: transparent !important;
  }

q::before {
    font-size: 20px;
    content: "\\201C";
}
q::after {
    font-size: 20px;
    content: "\\201D";
}

blockquote {
  font-family: Source Serif Pro, serif;
  position: relative;
  padding: 0.5em 2em 0.5em 2em;
  margin: 1em 3em 1em 2em;
  font-size: 1.2em;
}
blockquote:before {
    font-family: Source Serif Pro, serif;
    position: absolute;
    font-size: 3em;
    line-height: 1;
    top: 0;
    left: 0;
    content: "\\201C";
    color: ${(p) => p.theme.colors.brand};
}
blockquote:after {
    font-family: Source Serif Pro, serif;
    position: absolute;
    float:right;
    font-size:3em;
    line-height: 1;
    right:0;
    bottom:-0.5em;
    content: "\\201D";
    color: ${(p) => p.theme.colors.brand};
}
blockquote footer {
    padding: 0 2em 0 0;
    text-align:right;
}
blockquote cite:before {
    content: "\\2013";
}
`

/*
 */
