import styled from 'styled-components'


import mediaqueries from '../styles/media'

const Section = styled.section<{ narrow?: boolean; relative?: boolean; backgroundImage?: string }>`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 4rem;
  background: inherit;
  ${(p) =>
    p.backgroundImage ??
    `
  background-image: url(${p.backgroundImage});
  `}
  ${mediaqueries.desktop`
    max-width: 850px;
  `};

  ${(p) =>
    p.narrow
      ? mediaqueries.tablet`
          padding: 0 2rem;
          max-width: 527px;
        `
      : mediaqueries.tablet`
          padding: 0 4rem;
          max-width: 567px;
        `}

  ${mediaqueries.phablet`
    max-width: 100%;
  `};
`

export default Section
