import colors from './colors'
import tags from './tags'

import Logo from './logo.inline.svg'
import LogoMobile from './logo-mobile.inline.svg'

const breakpointsCombined = [
  ['phone_small', '320px'],
  ['phone', '376px'],
  ['phablet', '540px'],
  ['tablet', '735px'],
  ['desktop', '1070px'],
  ['desktop_medium', '1280px'],
  ['desktop_large', '1440px']
] as [string, string][]

const fonts: Record<string, string> = {
  serif: "'Source Serif Pro', Georgia, Serif",
  menu:
    "'Karla', 'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif",
  sans:
    "'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif",
  hero:
    "'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif",
  monospace: `"Operator Mono", Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`
}

fonts.runningheader = fonts.sans
fonts.headings = fonts.serif
fonts.paragraph = fonts.sans

const colorModeTransition =
  'background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)'

const googleFonts = ['Source Serif Pro', 'Karla']

const fontWeights = {
  thin: 100,
  normal: 300,
  menu: 400,
  header: 500,
  bold: 700
}

const textTransforms = {
  button: undefined,
  topmenu: undefined,
  runningheader: 'uppercase'
}

const borderRadius = {
  button: '8px'
}

export default {
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  initialColorMode: 'light',
  colorModeTransition,
  colors,
  fonts,
  borderRadius,
  breakpointsCombined,
  breakpoints: breakpointsCombined.map((bp) => bp[1]),
  breakpointLabels: breakpointsCombined.reduce((accum, bp, i) => {
    accum[bp[0]] = i
    return accum
  }, {}),
  tags,
  googleFonts,
  fontWeights,
  textTransforms,
  Logo,
  LogoMobile
}
